import React, { Component } from "react"

export class WhyUs extends Component {
    render() {
        return (
            <div className="py-14">
                <div className="container mx-auto">
                    <h1 className="text-2xl lg:text-3xl pb-2 font-bold text-center">What Makes <font className='text-dhred'>Dahlia Homes</font> Different?</h1>
                    <p className="text-2xl text-center px-5">We can buy your house in <font className='font-bold text-dhred'>ANY</font> situation!</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-10 2xl:px-24 gap-y-3 md:gap-x-5">

                        <div className="mx-auto border shadow-xl rounded-lg bg-black text-white text-center w-80 md:w-96 lg:w-72 xl:w-96">
                            <p className="text-2xl lg:text-3xl font-bold py-7">For <font className='text-dhred'>Any Reason</font></p>
                            <ul className="text-lg space-y-2 pb-7">
                                <li>Inherited Properties</li>
                                <li>Relocation</li>
                                <li>Upgrading</li>
                                <li>Downsizing</li>
                                <li>Foreclosure</li>
                                <li>Major Repairs Needed</li>
                                <li>Divorce</li>
                                <li>Bankruptcy</li>
                                <li>Health Issues</li>
                            </ul>
                        </div>

                        <div className="mx-auto border shadow-xl rounded-lg bg-black text-white text-center w-80 md:w-96 lg:w-72 xl:w-96">
                            <p className="text-2xl lg:text-3xl font-bold py-7">In <font className='text-dhred'>Any Condition</font></p>
                            <ul className="text-lg space-y-2 pb-7">
                                <li>Old Or Outdated House</li>
                                <li>Storm Damage</li>
                                <li>Fire Damage</li>
                                <li>Termite Damage</li>
                                <li>Hoarder House</li>
                                <li>Major Repairs Needed</li>
                                <li>Problem Tenants</li>
                                <li>Unfinished Construction</li>
                            </ul>
                        </div>

                        <div className="mx-auto border shadow-xl rounded-lg bg-black text-white text-center w-80 md:w-96 lg:w-72 xl:w-96">
                            <p className="text-2xl lg:text-3xl font-bold py-7">For <font className='text-dhred'>Any Issue</font></p>
                            <ul className="text-lg space-y-2 pb-7">
                                <li>Financial Issues</li>
                                <li>Title Issues</li>
                                <li>Code Violations</li>
                                <li>Hoarder Home</li>
                                <li>Low Equity</li>
                                <li>Out of Area Property</li>
                                <li>Family Matters</li>
                                <li>Mortgage Issues</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}