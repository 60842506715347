import React, { Component } from "react"
import GoogleIcon from "../icons/google-icon"
import { RedButton } from "../components/button-red"

export class ClientReviews extends Component {
    render() {
        const limitedReviews = this.props.reviewData.reviews.slice(0, 6)
        return (
            <div className="bg-dhgray py-8">
                <div className="container mx-auto py-5">
                    <h1 className="text-2xl lg:text-3xl pb-2 font-bold text-center mb-8">Our Happy Clients</h1>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-y-8">
                        {limitedReviews.map((review) => (
                            <div key={review.id} className="px-4 relative">
                                <div className="bg-white p-6 rounded-lg shadow-md flex items-center w-fit lg:w-[320px] xl:w-[410px] h-[300px] mx-auto">
                                    <div>
                                        <p className="text-lg font-semibold">{review.author}</p>
                                        <p className="text-gray-500 text-sm mb-2">{review.date}</p>
                                        <div className="flex mb-2">
                                            {[...Array(review.rating)].map((_, index) => (
                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 fill-current mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 1l2.46 6.755L19 7.755l-5.612 4.628L15.888 19 10 15.875 4.112 19l1.5-6.617L1 7.755l6.54-.19L10 1z" />
                                                </svg>
                                            ))}
                                        </div>
                                        <p className="text-gray-700">{review.review}</p>
                                    </div>
                                    <div className="absolute top-6 right-16 sm:right-32 md:right-16 lg:right-16 xl:right-24 w-2 h-2" >
                                        <GoogleIcon />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mx-auto flex justify-center pt-8">
                        <RedButton buttonPage="/reviews" buttonText="See More Reviews" />
                    </div>
                </div>
            </div>
        )
    }
}