import * as React from "react"

function GoogleIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.56 12.25C22.56 11.47 22.49 10.72 22.36 10H12V14.26H17.92C17.66 15.63 16.88 16.79 15.71 17.57V20.34H19.28C21.36 18.42 22.56 15.6 22.56 12.25Z" fill="#4285F4" />
      <path d="M12.0002 23C14.9702 23 17.4602 22.02 19.2802 20.34L15.7102 17.57C14.7302 18.23 13.4802 18.63 12.0002 18.63C9.14018 18.63 6.71018 16.7 5.84018 14.1H2.18018V16.94C3.99018 20.53 7.70018 23 12.0002 23Z" fill="#34A853" />
      <path d="M5.84 14.09C5.62 13.43 5.49 12.73 5.49 12C5.49 11.27 5.62 10.57 5.84 9.91001V7.07001H2.18C1.43 8.55001 1 10.22 1 12C1 13.78 1.43 15.45 2.18 16.93L5.03 14.71L5.84 14.09Z" fill="#FBBC05" />
      <path d="M12.0002 5.38C13.6202 5.38 15.0602 5.94 16.2102 7.02L19.3602 3.87C17.4502 2.09 14.9702 1 12.0002 1C7.70018 1 3.99018 3.47 2.18018 7.07L5.84018 9.91C6.71018 7.31 9.14018 5.38 12.0002 5.38Z" fill="#E22E00" />
    </svg>
  )
}
export default GoogleIcon