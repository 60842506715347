import React, { useState, useRef } from 'react'
import { LoadScript, Autocomplete } from '@react-google-maps/api'
import Lock from "../icons/lock"
import { Link } from "gatsby"

const libraries = ['places'];
const apiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY

const HomePageContactForm = (props) => {
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const autocompleteRef = useRef(null);

    const handlePlaceChanged = () => {
        if (autocompleteRef.current !== null) {
            const place = autocompleteRef.current.getPlace();
            if (place && place.formatted_address) {
                setAddress(place.formatted_address);
                setError('');
            } else {
                setError('No details available for the input: ' + place.name);
            }
        } else {
            setError('Autocomplete is not loaded yet.');
        }
    };

    return (
        <form name="Home Page Cash Offer Request" method="POST" data-netlify="true" className="py-5 lg:py-3 xl:py-0 mx-auto flex justify-center lg:justify-end">
            <input type="hidden" name="form-name" value="Home Page Cash Offer Request" />
            <input type="hidden" name="address" value={address} />
            <div className="flex justify-center lg:justify-end lg:-mt-[30rem] w-fit">
                <div className="px-4 md:px-10 pt-5 pb-4 bg-white rounded-xl">
                    <p className='text-black text-2xl font-bold max-w-xs xl:max-w-sm pb-5'>Get Your Quick And Fair Cash Offer Today!</p>
                    <p className='text-black max-w-xs xl:max-w-sm pb-5'>We purchase homes in any condition without any fees, commissions and obligations.</p>
                    <div className="flex flex-wrap mb-2">
                        <div className="w-80 lg:w-72 xl:w-96">
                            <label className="block tracking-wide text-black text-xs font-bold mb-2 text-left" htmlFor="text">Property Address:
                                <LoadScript
                                    googleMapsApiKey={apiKey}
                                    libraries={libraries}
                                    onLoad={() => console.log('Google Maps script loaded successfully')}
                                    onError={(e) => setError('Error loading Google Maps script: ' + e.message)}
                                >
                                    <Autocomplete
                                        onLoad={(autocomplete) => {
                                            autocompleteRef.current = autocomplete;
                                            autocomplete.setFields(['formatted_address']);
                                        }}
                                        onPlaceChanged={handlePlaceChanged}
                                    >
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-4 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="text" type="text" name="text" value={address} onChange={(e) => setAddress(e.target.value)} required placeholder="1234 Example Way, Sacramento CA 12345" />
                                    </Autocomplete>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </LoadScript >
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-wrap">
                        <div className="w-80 lg:w-72 xl:w-96">
                            <label className="block tracking-wide text-xs text-black font-bold text-left" htmlFor="phone">Phone Number:
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-4 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" name="phone" type="tel" required placeholder="EX: 916-245-0073" />
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="mt-6 mb-1 relative h-10 py-2 px-3 w-full mx-auto flex justify-center transition-colors rounded-lg focus:shadow-outline bg-gradient-to-r from-dhgreenfrom to-dhgreento text-white hover:shadow-lg hover:shadow-dhgreen font-semibold">
                        <span className="text-xs pt-1 sm:pt-0 sm:text-base">Get My FREE Home Offer!</span>
                    </button>

                    <div className="pt-5 flex justify-center">
                        <Link className="flex space-x-1 hover:opacity-70" to="/privacy-policy">
                            <Lock />
                            <span className="text-gray-400 text-xs mt-[0.1rem]">We Value Your Privacy</span>
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
}
export default HomePageContactForm;