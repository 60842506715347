import React, { Component } from "react"
import { Link } from "gatsby"
import GetOfferForm from "./get-offer-form"
import { OptionsForm } from "./options-form"

export class HomeBody extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h2 className="font-semibold text-2xl">We Are {this.props.city} Cash Home Buyers!</h2>
                            <p className="py-2">
                                We purchase homes in the {this.props.city} area, as well as other parts of California.
                                Our <Link to="/how-it-works" className="text-dhred hover:opacity-70">direct home buying process</Link> is simple and stress free. We value our clients, and as a bonus, we cover all closing costs that are incurred in the process.</p>
                            <p className="pt-2">If you are looking to sell your <b>{this.props.city} property</b> - get the process started today! Fill out our <Link to="/get-offer" className="text-dhred hover:opacity-70">"Free Home Offer Form"</Link> or call us at <a className="hover:opacity-70 text-dhred" href="tel:916-245-0073" title="Call Dahlia Homes" alt="Call Dahlia Homes">(916) 245-0073.</a></p>

                            <h2 className="pt-4 font-semibold text-2xl">The Easiest Way To Sell Your {this.props.city} House Fast</h2>
                            <p className="py-2">
                                At Dahlia homes we believe that the process of selling your home should be fast, easy, and stress-free for {this.props.city} home sellers.
                                If you are thinking about selling your house, we are ready to give you a fair offer on your home.
                                We purchase homes AS-IS, meaning that you do not have to spend extra money on repairs or maintenance.
                                Dahlia Homes makes the process as easy and convenient as possible for you!
                            </p>

                            <p className="font-semibold text-2xl pt-5">When Should You Turn To Dahlia Homes</p>
                            <p className="py-2">
                                We help owners who want to sell their house without the hassle of dealing with agents or wholesalers, have inherited unwanted property, or need their property sold quickly and stress-free.
                                Whether you own a vacant house, are behind on payments, owe liens, downsized and can’t sell, or even if the house needs repairs that you do not want to deal with… and yes, even if the house is fire damaged or has bad rental tenants,
                                you can count on Dahlia Homes to help you!
                            </p>

                            <h3 className="pt-5 font-semibold text-2xl">What are the benefits of selling your {this.props.city} house directly to Dahlia homes?</h3>
                            <p className="py-2">
                                Dahlia Homes provides an option for selling your {this.props.city} home with no listing fees, no agent commissions and no closing costs! We take care of all repairs and renovations, so that you can
                                sell your home without dealing with all of the headaches and stress that follows.
                            </p>

                            <p className="pt-5 font-semibold text-2xl">Why List Your House When You Can Sell Directly To Our Team And Walk Away With Cash</p>
                            <p className="py-2">
                                We help property owners get the best deal for their home, no matter what situation they are in. We purchase homes throughout {this.props.city} and all of California for fair market values. We make it easy for you to sell your home quickly!
                            </p>

                            <p className="py-2">
                                If you need to sell your house fast in California, <Link to="/get-offer" className="text-dhred hover:opacity-70">connect with Dahlia Homes</Link>. we’d love to make you a fair no-obligation no-hassle offer!
                            </p>
                        </div>

                        <div className="mx-auto py-4">
                            {/* TODO: Change src to team photo and uncomment
                            <div className="max-w-xl pb-10 mx-auto">
                                <StaticImage src="../images/home-main-image.png" alt="Dahlia Homes Team" title="Dahlia Homes Team" className="shadow-xl" />
                            </div> */}

                            <div className="flex justify-center">
                                <GetOfferForm formName="Bottom Section On Home Page" />
                            </div>

                            <div className="flex justify-center">
                                <OptionsForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}