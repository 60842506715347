import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"
import ArrowRight from "../icons/arrow-right"
import ArrowDown from "../icons/arrow-down"

export class ProcessBanner extends Component {
    render() {
        return (
            <div className="container mx-auto py-5">

                <p className="text-2xl lg:text-3xl pb-2 font-bold text-center">How The Process Works</p>
                <div className="px-5 lg:px-10 grid lg:grid-cols-7 py-5 text-xs md:text-sm justify-center text-center">
                    {/* Grid 1 */}
                    <div className="max-w-xs mx-auto lg:flex">
                        <div>
                            <StaticImage src="../images/house.png" title="Sell Your Home Fast" alt="Sell Your Home Fast" className="w-14 " />
                            <p className="pt-2 font-bold">Tell Us About Your Home</p>
                            <p>Tell us about your property to start the process of selling your home</p>
                        </div>
                    </div>
                    {/* Grid 2 */}
                    <div className="hidden lg:flex justify-center items-center px-3">
                        <ArrowRight />
                    </div>
                    <div className="lg:hidden py-2 flex justify-center">
                        <ArrowDown />
                    </div>
                    {/* Grid 3 */}
                    <div className="max-w-xs mx-auto lg:flex">
                        <div>
                            <StaticImage src="../images/schedule.png" title="Sell Your Home As Is" alt="Sell Your As Is" className="w-14" />
                            <p className="pt-2 font-bold">Schedule A Walk Through</p>
                            <p>Take us for a walk through of your property to help us make an accurate offer</p>
                        </div>
                    </div>
                    {/* Grid 4 */}
                    <div className="hidden lg:flex justify-center items-center px-3">
                        <ArrowRight />
                    </div>
                    <div className="lg:hidden py-2 flex justify-center">
                        <ArrowDown />
                    </div>
                    {/* Grid 5 */}
                    <div className="max-w-xs mx-auto lg:flex">
                        <div>
                            <StaticImage src="../images/email.png" title="No Commissions" alt="No Commissions" className="w-14" />
                            <p className="pt-2 font-bold">Receive An Offer</p>
                            <p>We take care of all the paperwork and hassle that comes after accepting the offer</p>
                        </div>
                    </div>
                    {/* Grid 6 */}
                    <div className="hidden lg:flex justify-center items-center px-3">
                        <ArrowRight />
                    </div>
                    <div className="lg:hidden py-2 flex justify-center">
                        <ArrowDown />
                    </div>
                    {/* Grid 7*/}
                    <div className="max-w-xs mx-auto">
                        <StaticImage src="../images/payment.png" title="Instant Cash Offers" alt="Instant Cash Offers" className="w-14" />
                        <p className="pt-2 font-bold">Close And Receive Your Cash</p>
                        <p>Get your money in as little as a few days, you decide the pace</p>
                    </div>
                </div>
            </div>
        )
    }
}